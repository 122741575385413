import axios from "@/axios.js";

export default {
  addItem({ commit }, item) {
    return new Promise((resolve, reject) => {
      debugger;
      axios
        .post("API/NoticeVisitAndRent/AddNotice", item)
        .then(response => {
          debugger;
          resolve(response);
          commit(
            "ADD_ITEM",
            Object.assign(item, { ID: response.data.data.ID })
          );
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  fetchDataListItems({ commit }) {
    debugger;
    return new Promise((resolve, reject) => {
      axios
        .get("API/NoticeVisitAndRent/GetAllUintDeliveryStatus")
        .then(response => {
          debugger;
          resolve(response);
          commit("SET_NoticeVisitAndRents", response.data.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  updateItem(context, item) {
    debugger;
    return new Promise((resolve, reject) => {
      axios
        .post("API/NoticeVisitAndRent/UpdateNotice", item)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
//ApproveOrCancelNotice
ApproveOrCancelNoticeRequest(context, item) {
  debugger;
  return new Promise((resolve, reject) => {
    axios
      .put(`API/NoticeVisitAndRent/ApproveOrCancelNotice?Id=${item.Id}&statusId=${item.statusId}`)
      .then(response => {
        resolve(response);
      })
      .catch(error => {
        reject(error);
      });
  });
},

  GetItemByID(context, itemid) {
    return new Promise((resolve, reject) => {
      debugger;
      axios
      .get("API/NoticeVisitAndRent/GetNoticById?Id=" + itemid)
        .then(response => {
          debugger;
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  GetRentsCount() {
    return new Promise((resolve, reject) => {
      debugger;
      axios
      .get("API/NoticeVisitAndRent/GetRentsNotApprovedCount")
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },


  GetVisitsCount() {
    return new Promise((resolve, reject) => {
      debugger;
      axios
      .get("API/NoticeVisitAndRent/GetVisitorsNotApprovedCount")
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },


  GetVisitorsNotExitCount() {
    return new Promise((resolve, reject) => {
      debugger;
      axios
      .get("API/NoticeVisitAndRent/GetVisitorsNotExitCount")
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },


  SearchNoticeVisitAndRent({ commit }, search) {
    debugger;
      return new Promise((resolve, reject) => {
        axios
          .post("API/NoticeVisitAndRent/SearchNotice", search)
          .then(response => {
            debugger;
            resolve(response);
            commit("SET_NoticeVisitAndRents", response.data.data);
          })
          .catch(error => {
            reject(error);
          });
      });
    },
  DeleteObject({ commit }, item) {
    return new Promise((resolve, reject) => {
      axios
        .delete("API/NoticeVisitAndRent/DeleteNotice?Id=" + item.ID)
        .then(response => {
          resolve(response);
          commit("REMOVE_ITEM", item.ID);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  SaveReasonOfRefuseAdmin(context, item) {
    debugger;
    return new Promise((resolve, reject) => {
      axios
        .put("API/NoticeVisitAndRent/SaveReasonOfRefuseAdmin", item)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  UploadImage(context, image) {
    debugger;
    const formData = new FormData();
    formData.append("file",image, image.name);
    return new Promise((resolve, reject) => {
      axios
        .post("/API/Common/UploadImage", formData, {
          headers: {
            "Content-Type": "multipart/form-data"
          }
        })
        .then(response => {
          if (response.status == 200) {
            resolve(response);
          }
        })
        .catch(error => {
          reject(error);
        });
    });
  },
};
