<template>
  <div id="page-user-edit">
    <vs-alert
      color="danger"
      title="Notic Not Found"
      :active.sync="user_not_found"
    >
      <span>Notic record with id: {{ $route.params.ID }} not found. </span>
      <span>
        <span>Check </span>

        <router-link
          :to="{ name: 'page-user-list' }"
          class="text-inherit underline"
          >All Notic
        </router-link>
      </span>
    </vs-alert>


    <vx-card
      ref="ownerCard"
      :title="$t('UnitOwnerInformation')"
      class="user-list-filters mb-6"
      collapse-action
    >

    <template>
      <div class="vx-row w-full">
            <div class="vx-col lg:w-1/4">
              <label class="text-sm opacity-75">{{ $t("Owner") }}</label>
              <vs-input
                v-model="NoticModel.ownerName"
                class="w-full"
                :data-vv-as="$t('Owner')"
                name="UnitOwnerName"
                :disabled=true
              />
            </div>

            <div class="vx-col lg:w-1/4">
              <label class="text-sm opacity-75">{{ $t("UnitModel") }}</label>
              <vs-input
                v-model="NoticModel.unitModelName"
                class="w-full"
                :data-vv-as="$t('UnitModel')"
                name="UnitModelName"
                :disabled=true
              />
            </div>

            <div class="vx-col lg:w-1/4">
              <label class="text-sm opacity-75">{{ $t("UnitType") }}</label>
              <vs-input
                v-model="NoticModel.unitTypeName"
                class="w-full"
                :data-vv-as="$t('UnitType')"
                name="UnitTypeName"
                :disabled=true
              />
            </div>

            <div class="vx-col lg:w-1/4">
              <label class="text-sm opacity-75">{{ $t("buildingNumber") }}</label>
              <vs-input
                v-model="NoticModel.buildingName"
                class="w-full"
                :data-vv-as="$t('buildingNumber')"
                name="buildingNumber"
                :disabled=true
              />
            </div>
      </div>
    </template>
    </vx-card>

    <vx-card
    ref="visitorCard"
      :title="$t('VisitorData')"
      class="user-list-filters mb-8"
      collapse-action
    >
      <div class="vx-row w-full">
            <div class="vx-col lg:w-1/4">
              <label class="text-sm opacity-75">{{ $t("Visitor") }}</label>
              <vs-input
                v-model="NoticModel.nameAr"
                class="w-full"
                :data-vv-as="$t('NameAr')"
                name="NameAr"
                :disabled=true
              />
            </div>

            <div class="vx-col lg:w-1/4">
              <vx-row>
                <div class="vx-col ">
                  <label class="text-sm opacity-75">{{ $t("VisitType") }}</label>
                  <vs-input
                  name="IsRent"
                  class="w-full"
                  :value="NoticModel.isRent == true?  $t('Rent') : $t('Visit')"
                  :disabled="true"
              />

                </div>
              </vx-row>
            </div>

            <div class="vx-col lg:w-1/4">
              <label class="text-sm opacity-75">{{ $t("VistorCount") }}</label>
              <vs-input
                v-model="NoticModel.totalWithVistiorCount"
                class="w-full"
                :data-vv-as="$t('VistorCount')"
                name="VistorCount"
                :disabled=true
              />
            </div>

            <div class="vx-col lg:w-1/4">
              <label class="text-sm opacity-75">{{
                $t("IdentityNumber")
              }}</label>
              <vs-input
                v-model="NoticModel.nationalId"
                class="w-full"
                :data-vv-as="$t('IdentityNumber')"
                name="IdentityNumber"
                :disabled=true
              />
            </div>

            <div class="vx-col lg:w-1/4">
              <label class="text-sm opacity-75">{{ $t("PhoneNumber") }}</label>
              <vs-input
                v-model="NoticModel.phoneNumber"
                class="w-full"
                :data-vv-as="$t('PhoneNumber')"
                name="PhoneNumber"
                :disabled=true
              />
            </div>

            <div class="vx-col lg:w-1/4">
              <label class="text-sm opacity-75">{{ $t("EntranceTime") }}</label>
              <datepicker
                name="EntranceTime"
                format="yyyy-MM-dd"
                v-model="NoticModel.entryDateTime"
                :disabled=true

              ></datepicker>
              <span
                class="text-danger text-sm"
                v-show="errors.has('EntranceTime')"
                >{{ errors.first("EntranceTime") }}</span
              >
            </div>

            <div class="vx-col lg:w-1/4">
              <label class="text-sm opacity-75">{{ $t("ExitTime") }}</label>
              <datepicker
                name="ExitTime"
                format="yyyy-MM-dd"
                v-model="NoticModel.ckeckOutDateTime"
                :disabled=true
              ></datepicker>
              <span
                class="text-danger text-sm"
                v-show="errors.has('ExitTime')"
                >{{ errors.first("ExitTime") }}</span
              >
            </div>

            <div class="vx-col lg:w-1/4">
              <label class="text-sm opacity-75">{{ $t("ServiceStatus") }}</label>
              <vs-input
                v-model="NoticModel.statusName"
                class="w-full"
                :data-vv-as="$t('ServiceStatus')"
                name="statusName"
                :disabled=true
              />
            </div>


            <div class="vx-col lg:w-1/4">
              <label class="text-sm opacity-75">{{ NoticModel.isDeny == true ? $t("IsDeny") : $t("IsAllow") }}</label>
              <vs-checkbox
                v-model="NoticModel.checked"
                :disabled="true"
              ></vs-checkbox>
            </div>
            <div v-if="NoticModel.isDeny == true" class="vx-col lg:w-1/4">
              <label class="text-sm opacity-75">{{ $t("Reason") }}</label>
              <vs-input
                v-model="NoticModel.denyReason"
                class="w-full"
                :disabled=true
              />
            </div>
      </div>

        <br>

      <div class="vx-row w-full">
       <div class="vx-col lg:w-1/4">
          <vs-card title="icon">
            <h4>{{ $t("IdFront") }}</h4>
            <template v-if="NoticModel.imageNationalIdFrontURL">
              <div
                class="img-container w-32 h-32 mx-auto flex items-center justify-center"
              >
                <img
                  :src="baseURL + NoticModel.imageNationalIdFrontURL"
                  class="w-full h-full object-contain"
                />
              </div>
            </template>


          </vs-card>

        </div>

        <div class="vx-col lg:w-1/4">
          <vs-card title="icon">
            <h4>{{ $t("IdBack") }}</h4>
            <template v-if="NoticModel.imageNationalIdBackURL">
              <div
                class="img-container w-32 h-32 mx-auto flex items-center justify-center"
              >
                <img
                  :src="baseURL + NoticModel.imageNationalIdBackURL"
                  class="w-full h-full object-contain"
                />
              </div>
            </template>


          </vs-card>
        </div>
      </div>
      <div class="vx-row" v-if="NoticModel.statusId == 1">

        <div class="vx-col lg:w-1/4"></div>
        <div class="vx-col lg:w-1/4">
          <vs-button class="my-6 rounded-sm" color="warning" @click="ApproveNoticeRequest(Id)">
            {{ $t("Approve") }}
          </vs-button>
        </div>

        <div class="vx-row lg:w-1/4">

          <div class="vx-col lg:w-1/2">
          <vs-button class="my-6 rounded-sm" color="warning" @click="CheckNotice">
            {{ $t("Reject") }}
          </vs-button>
        </div>

        <div class="vx-col lg:w-1/2" v-if="Show == true" >


          <vs-button class="my-6 rounded-sm" color="info" @click="saveReasonOfRefuse">
            {{ $t("Save") }}
          </vs-button>


              <label class="text-sm opacity-75">{{ $t("reasonofrefuse") }}</label>
              <vs-textarea
                id="Text-area"
                v-model="SaveReasonOfRefuseAdmin"
                class="w-full"
                :data-vv-as="$t('Details')"
                name="Details"
              />
            </div>

          </div>

      </div>
     <div class="vx-row">
      <div class="vx-col lg:w-2/4"></div>
        <div class="vx-col lg:w-1/4">
          <vs-button class="my-6 rounded-sm" color="warning" @click="cancel">
            {{ $t("Back") }}
          </vs-button>
        </div>
      </div>
    </vx-card>
  </div>
</template>

<script>
import VxCard from "../../components/vx-card/VxCard.vue";
import { domain } from "@/gloabelConstant.js";
import moduleNoticeVisitAndRentModel from "@/store/NoticeVisitAndRent/moduleNoticeVisitAndRent.js";
import moduleAdsManagement from "@/store/AdsManagement/moduleAdsManagement.js";
import Datepicker from "vuejs-datepicker";

export default {
  components: { VxCard, Datepicker },
  data() {
    return {
      baseURL: domain,
      user_not_found: false,
      activeTab: 0,

      Id: 0,
      Show:false,
SaveReasonOfRefuseAdmin:"",

      NoticModel: {
        nameAr: "",
        nameEn: "",
        buildingName: null,
        ownerName:null,
        entryDateTime: null,
        ckeckOutDateTime: null,
        imageNationalIdFrontURL: "",
        imageNationalIdBackURL: "",
        totalWithVistiorCount: 0,
        nationalId: "",
        userId: "",
        qRCodeFilePath: "",
        isRent: false,
        uintName: "",
        unitOwnerName: "",
        isDeny:null,
        denyReason:"",
        checked:true,

      },
      search: {
        nationalId: "",
        phoneNumber: "",
        name: "",
        pageNumber: 0,
        pageSize: 20,
      },
    };
  },
  computed: {
    // UintOwnerTypes(){
    //   return this.$store.state.unitOwnerTypeList.UintOwnerTypes;
    // }
  },
  methods: {
    // addNotic() {
    //   debugger;
    //   this.NoticModel.nameEn = this.NoticModel.nameAr;

    //   if (this.NoticModel.ID !== null && this.NoticModel.ID >= 0) {
    //     this.$store
    //       .dispatch("modulNoticeList/updateItem", this.NoticModel)
    //       .then(() => {
    //         debugger;
    //         window.showUpdateSuccess();
    //         this.$vs.loading.close();
    //         this.initValues();
    //         this.cancle();
    //       })
    //       .catch((err) => {
    //         if (err.response.status == 400) {
    //           window.showUpdateFailed(err.response.data.message);
    //         } else {
    //           window.showUpdateFailed("");
    //         }
    //         this.$vs.loading.close();
    //       });
    //   } else {
    //     this.$store
    //       .dispatch("modulNoticeList/addItem", this.NoticModel)
    //       .then(() => {
    //         debugger;
    //         window.showAddSuccess();
    //         this.initValues();
    //         this.$vs.loading.close();
    //         this.cancle();
    //       })
    //       .catch((err) => {
    //         if (err.response.status == 400) {
    //           window.showAddFailed(err.response.data.message);
    //         } else {
    //           window.showAddFailed("");
    //         }
    //         this.$vs.loading.close();
    //       });
    //   }
    // },
    saveReasonOfRefuse(){
      debugger;
      try {
        debugger;
        const result =  this.$validator.validateAll();
        if (result) {
          const obj = {
            Id: this.Id,
            SaveReasonOfRefuseAdmin: this.SaveReasonOfRefuseAdmin,

          };
          console.log("Id f:" + obj.Id);
            debugger;

            this.$store
             .dispatch("modulNoticeList/SaveReasonOfRefuseAdmin", obj)
             .then(() => {
              this.CancelNoticeRequest(this.Id)
            });




        }
      } catch (error) {
        console.error(error);
      }

    },
    CheckNotice() {
      debugger;
      this.Show = true;


    },
    CancelNoticeRequest(data) {

      debugger;
      this.statusId = 3;
      const obj = {
        Id: data,
        statusId: this.statusId,

      };
      this.$store
        .dispatch("modulNoticeList/ApproveOrCancelNoticeRequest", obj)
        .then(() => {
          this.cancelsuccess();
          this.cancel();
          this.$store.dispatch("modulNoticeList/SearchNoticeVisitAndRent", this.search)

        });
    },

    ApproveNoticeRequest(data) {
      debugger;
      this.statusId = 2;
      const obj = {
        Id: data,
        statusId: this.statusId,

      };

      this.$store
        .dispatch("modulNoticeList/ApproveOrCancelNoticeRequest", obj)
        .then(() => {
          debugger;
          this.approvedsuccess();
          this.cancel();
          this.$store.dispatch("modulNoticeList/SearchNoticeVisitAndRent", this.search)

        });
    },

    cancelsuccess() {
      this.$vs.notify({
        color: "success",
        title: "Cancel Successfully",
        text: this.$t("CancelSuccessfully"),
      });
    },

    approvedsuccess() {
      this.$vs.notify({
        color: "success",
        title: "Approved Successfully",
        text: this.$t("ApprovedSuccessfully"),
      });
    },

    reset_data() {
      this.initValues();
    },

    initValues() {
      this.NoticModel = {};
    },

    cancel() {
      this.$router.go(-1);
    },
    // async updateCurrImgFront(input) {
    //   debugger;
    //   this.$vs.loading();
    //   debugger;
    //   if (input.target.files && input.target.files[0]) {
    //     const file = input.target.files[0];
    //     this.$store
    //       .dispatch("modulNoticeList/UploadImage", file)
    //       .then((res) => {
    //         if (res.status == 200) {
    //           this.NoticModel.imageNationalIdFrontURL = res.data;
    //           this.successUpload();
    //           this.$vs.loading.close();
    //         }
    //         this.$vs.loading.close();
    //       })
    //       .catch(() => {
    //         this.$vs.loading.close();
    //         window.showError();
    //       });
    //   }
    // },

    // async updateCurrImgBack(input) {
    //   this.$vs.loading();
    //   debugger;
    //   if (input.target.files && input.target.files[0]) {
    //     const file = input.target.files[0];
    //     this.$store
    //       .dispatch("modulNoticeList/UploadImage", file)
    //       .then((res) => {
    //         if (res.status == 200) {
    //           this.NoticModel.imageNationalIdBackURL = res.data;
    //           this.successUpload();
    //           this.$vs.loading.close();
    //         }
    //         this.$vs.loading.close();
    //       })
    //       .catch(() => {
    //         this.$vs.loading.close();
    //         window.showError();
    //       });
    //   }
    // },



  },
  created() {
    if (!moduleNoticeVisitAndRentModel.isRegistered) {
      this.$store.registerModule( "modulNoticeList",moduleNoticeVisitAndRentModel);
      moduleNoticeVisitAndRentModel.isRegistered = true;
    }

    if (!moduleAdsManagement.isRegistered) {
      this.$store.registerModule(
        "AdsManagementList",
        moduleAdsManagement
      );
      moduleAdsManagement.isRegistered = true;
    }

    debugger;
    const ID = this.$route.params.ID;
    debugger;
    if (ID != undefined && ID != 0) {
      debugger;
      this.$store.dispatch("modulNoticeList/GetItemByID", ID).then((res) => {
        debugger;
        this.NoticModel = res.data.data;
        this.NoticModel.checked = true;
        console.log(this.NoticModel);
      });
    } else if (ID == undefined) {
      ID == 0;
    }

    this.Id = ID;
  },
  mounted() {
    this.isMounted = true;
  },
};
</script>

<style>
/* Add any additional styling here */
</style>
