
import state from "./moduleAdsManagementState.js"
import mutations from "./moduleAdsManagementMutations.js"
import getters from "./moduleAdsManagementGetters.js"
import actions from './moduleAdsManagementActions.js';
export default {
	namespaced: true,
    state: state,
    mutations: mutations,
    actions: actions,
    getters: getters
}
