import axios from "@/axios.js";

export default {

  fetchDataListItems({ commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get("API/Ads/GetAllAds")
        .then(response => {
          debugger;
          resolve(response);
          commit("SET_AdsManagements", response.data.Data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  SearchUnitOwner({ commit }, search) {
  debugger;
    return new Promise((resolve, reject) => {
      axios
        .post("API/Ads/AdsSearch",search)
        .then(response => {
          resolve(response);
          commit("SET_AdsManagements", response.data.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  updateItem(context, item) {
    debugger;
    return new Promise((resolve, reject) => {
      axios
        .post("API/Ads/Update", item)
        .then(response => {
          resolve(response);
         // commit("UPDATE_UnitOwner", item);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  GetItemByID({ commit }, id) {
    debugger;
    return new Promise((resolve, reject) => {
      axios
      .get(`API/Ads/GetById/${id}`)
        .then(response => {
          debugger;
          resolve(response);
          commit("SET_AdsManagements", response.data.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },


  GetUnitOwnersNotApproved() {
    return new Promise((resolve, reject) => {
      debugger;
      axios
      .get("API/UnitOwner/GetNewOwnersCount")
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  GetOwnersNotApproved() {
    return new Promise((resolve, reject) => {
      debugger;
      axios
      .get("API/UnitOwner/GetUnitOwnersNotApproved")
        .then(response => {
          debugger;
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  DeleteUnitOwner({ commit }, item) {
    debugger;
    return new Promise((resolve, reject) => {
      axios
        .delete("API/Ads/Delete?Id=" + item.id)
        .then(response => {
          resolve(response);
          commit("REMOVE_ITEM", item.ID);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  UploadImage(context, image) {
    const formData = new FormData();
    formData.append("file",image, image.name);
    return new Promise((resolve, reject) => {
      axios
        .post("/API/Common/UploadFile", formData, {
          headers: {
            "Content-Type": "multipart/form-data"
          }
        })
        .then(response => {
          if (response.status == 200) {
            resolve(response);
          }
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  BlockOrUnBlockUser(context, Id) {
    debugger;
    return new Promise((resolve, reject) => {
      axios
        .put("API/UnitOwner/BlockOrUnBlockUser?Id="+Id)
        .then(response => {
          debugger;
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  UnBlockUser(context, Id) {
    debugger;
    return new Promise((resolve, reject) => {
      axios
        .put("API/UnitOwner/UnBlockUnitOwner?Id="+Id)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  ApproveOrDenayUnitOwner(context, Id) {
    debugger;
    return new Promise((resolve, reject) => {
      axios
        .put("API/Authentication/AcceptRegistrationRequests?Id="+Id)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

};
