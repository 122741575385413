
import state from "./moduleNoticeVisitAndRentState.js"
import mutations from "./moduleNoticeVisitAndRentMutations.js"
import getters from "./moduleNoticeVisitAndRentGetters.js"
import actions from './moduleNoticeVisitAndRentActions.js';
export default {
	namespaced: true,
    state: state,
    mutations: mutations,
    actions: actions,
    getters: getters
}
