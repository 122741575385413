export default {
  ADD_ITEM(state, item) {
    debugger;
    state.AdsManagements.unshift(item);
  },
  SET_AdsManagements(state, AdsManagements) {
    debugger;
    state.AdsManagements = AdsManagements;
  },
  UPDATE_AdsManagement(state, AdsManagement) {
    const AdsManagementIndex = state.AdsManagements.findIndex(p => p.ID == AdsManagement.ID);
    if (AdsManagementIndex != -1) {
      Object.assign(state.AdsManagements[AdsManagementIndex], AdsManagement);
    }
  },
  REMOVE_ITEM(state, itemId) {
    const ItemIndex = state.AdsManagements.findIndex(p => p.ID == itemId);
    state.AdsManagements.splice(ItemIndex, 1);
  }
};
