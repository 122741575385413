export default {
  ADD_ITEM(state, item) {
    state.NoticeVisitAndRents.unshift(item);
  },
  SET_NoticeVisitAndRents(state,  NoticeVisitAndRents) {
    state.NoticeVisitAndRents =  NoticeVisitAndRents;
  },
  UPDATE_NoticeVisitAndRents(state,  NoticeVisitAndRents) {
    const  NoticeVisitAndRentsIndex = state.NoticeVisitAndRents.findIndex(p => p.ID ==  NoticeVisitAndRents.ID);
    if ( NoticeVisitAndRentsIndex != -1) {
      Object.assign(state. NoticeVisitAndRents[ NoticeVisitAndRentsIndex],  NoticeVisitAndRents);
    }
  },
  REMOVE_ITEM(state, itemId) {
    const ItemIndex = state. NoticeVisitAndRents.findIndex(p => p.ID == itemId);
    state.NoticeVisitAndRents.splice(ItemIndex, 1);
  }
};

